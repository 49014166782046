<template>
  <div v-if="isVisible" class="wait-for-element-spinner__wrapper">
    <a-spinner
      v-if="!isSelectorFoundInDom && !isTimeoutOrError"
      v-bind="$attrs"
    />
    <p v-else-if="isMessageVisible" class="wait-for-element-spinner__message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import ASpinner from 'shared/ASpinner'
import { waitForElAppearsInDom } from '@fmpedia/helpers'

const DEFAULT_TIMEOUT = 30000

export default {
  name: 'AWaitForElementSpinner',
  model: {
    prop: 'isLoaded'
  },
  props: {
    isLoaded: propValidator([PROP_TYPES.BOOLEAN], false),
    selector: propValidator([PROP_TYPES.STRING]),
    timeout: propValidator([PROP_TYPES.NUMBER], false, DEFAULT_TIMEOUT),
    errorMessage: propValidator([PROP_TYPES.STRING], false)
  },
  components: { ASpinner },
  data() {
    return {
      isSelectorFoundInDom: false,
      isTimeoutOrError: false
    }
  },
  computed: {
    isVisible() {
      return !this.isSelectorFoundInDom || this.isTimeoutOrError
    },
    isMessageVisible() {
      return this.isTimeoutOrError && !!this.errorMessage
    }
  },
  methods: {
    async observeChangesInDom() {
      try {
        const isFoundInDom = await waitForElAppearsInDom({
          selector: this.selector,
          timeout: this.timeout
        })
        if (isFoundInDom) {
          this.isSelectorFoundInDom = true
          this.$emit('input', true)
        } else {
          this.isTimeoutOrError = true
          this.$emit('input', false)
        }
      } catch (err) {
        this.isTimeoutOrError = true
        this.$emit('input', false)
      }
    }
  },
  mounted() {
    this.observeChangesInDom()
  }
}
</script>
<style lang="scss">
.wait-for-element-spinner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .wait-for-element-spinner__message {
    color: $c--gray-main;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
