<template>
  <div class="header-actions__container">
    <div
      v-if="!mobile"
      class="header-actions__element header-actions__element_search"
    >
      <search-icon class="header-actions__search" />
    </div>

    <a-visibility hide :on="[$breakpoint.mobile, $breakpoint.tablet]">
      <div class="header-actions__element">
        <subscribe-icon
          :data-ref="$options.consts.REFS.ONE_SIGNAL_DESKTOP"
          :size="$options.consts.HEADER_ICON_SIZE.DESKTOP"
          class="one-signal-desktop-container"
        />
      </div>
    </a-visibility>

    <a-visibility show :on="[$breakpoint.mobile, $breakpoint.tablet]">
      <newsletter-icon @open-newsletter="openNewsletter" />
    </a-visibility>

    <div
      v-if="isNotificationIconVisible"
      class="header-actions__element header-actions__element_alarm"
    >
      <notification-icon :notification-count="newNotificationCount" />
    </div>
    <div
      class="header-actions__element header-actions__element_sign-btn"
      :data-ref="$options.consts.REFS.LOGIN_ICON_WRAPPER"
    >
      <!--      Fixes the component-cache DOM mismatch issue, do not remove-->
      <client-only>
        <div v-if="isSpinnerVisible" class="header-actions__spinner">
          <a-spinner :size="spinnerSize" />
        </div>
        <user-icon
          v-else-if="isLoggedIn"
          :id="$options.consts.ACTIVATOR_ID.AUTH"
          class="header-actions__avatar"
        />
        <button
          v-else
          :id="$options.consts.ACTIVATOR_ID.AUTH"
          :class="buttonClasses"
          :disabled="isPreviewMode"
          @click="openAuthForm"
        >
          Sign in
        </button>
      </client-only>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import { MODAL } from '@/components/_modals/AModalWrapper'
import { REFS } from 'enums/external-refs'
import ASpinner from 'shared/ASpinner'
import * as types from '@/store/mutation-types'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { HEADER_ICON_SIZE } from 'enums/header'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

export default {
  name: 'AHeaderActions',
  mixins: [hydrationHelpers],
  components: {
    NotificationIcon: () => import('../../Icons/NotificationIcon'),
    ASpinner,
    UserIcon: () => import('../../Icons/UserIcon'),
    SubscribeIcon: () => import('../../Icons/SubscribeIcon'),
    NewsletterIcon: () => import('../../Icons/NewsletterIcon'),
    SearchIcon: () => import('../../Icons/SearchIcon')
  },
  props: {
    mobile: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: { REFS, HEADER_ICON_SIZE, ACTIVATOR_ID },
  data() {
    return {
      isDisabledNotifications: true
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isPreviewMode: 'isPreviewMode',
      isAuthFormLoadingInProgress: 'auth/isAuthFormLoadingInProgress',
      isAuthStatusRequestInProgress: 'auth/isAuthStatusRequestInProgress',
      isAuthFormLoaded: 'auth/isAuthFormLoaded',
      newNotificationCount: 'notifications/newNotificationCount'
    }),
    buttonClasses() {
      return {
        'header-actions__sign-btn': true,
        'header-actions__sign-btn_mobile': this.mobile,
        disabled: this.isPreviewMode
      }
    },
    isNotificationIconVisible() {
      const isShownOnMobile =
        this.$_hydrationHelpers_isLayoutMobileOrTablet && this.isLoggedIn
      return isShownOnMobile || !this.$_hydrationHelpers_isLayoutMobileOrTablet
    },
    isNewsletterIconVisible() {
      return this.$_hydrationHelpers_isLayoutMobile && !this.isLoggedIn
    },
    spinnerSize() {
      return this.$_hydrationHelpers_mobileOrDesktopValue(
        HEADER_ICON_SIZE.MOBILE,
        HEADER_ICON_SIZE.DESKTOP
      )
    },
    isSpinnerVisible() {
      return (
        this.isAuthFormLoadingInProgress || this.isAuthStatusRequestInProgress
      )
    }
  },
  methods: {
    ...mapActions({
      deauthenticateUser: 'auth/deauthenticateUser'
    }),
    ...mapMutations({
      setAuthFormLoadingInProgress: `auth/${types.SET_AUTH_FORM_LOADING_IN_PROGRESS}`
    }),
    openNewsletter() {
      this.$helper.openModal(MODAL.NEWSLETTER_FORM)
    },
    openAuthForm() {
      if (!this.isAuthFormLoaded) {
        this.setAuthFormLoadingInProgress()
      }

      this.$helper.openModal(MODAL.AUTH_FORM, {
        activatorSelector: ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[ACTIVATOR_ID.AUTH]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$content-width: 1460px;

.header-actions__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  position: relative;

  @include desktop-md {
    justify-content: flex-start;
  }

  $action-container: 520px;

  .header-actions__element_search {
    width: percentage(420px / $action-container);
  }

  .header-actions__element_sign-btn {
    height: $header-icon-desktop;

    @include tablet {
      width: 40px;
    }

    @include mobile {
      height: $header-icon-mobile;
    }
  }

  .header-actions__search {
    margin-left: auto;
  }

  /deep/ .header-actions__search .svg-icon {
    fill: $c--white;
  }

  /deep/ .header-actions__search .svg-icon:hover {
    fill: $c--black;
  }

  .header-actions__element {
    margin-right: $header-icon-gap-desktop;

    @include tablet {
      margin-right: $header-icon-gap-mobile;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.header-actions__spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-actions__avatar {
  cursor: pointer;
  border: 1px solid white;

  @include header-icon-size;
}

.header-actions__sign-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: $header-icon-desktop;
  white-space: nowrap;
  border: 2px solid $c--white;
  border-radius: 5px;
  background-color: $c--white;
  font-size: 16px;
  line-height: 19px;
  color: $c--gray-main;

  @include tablet {
    width: 40px;
    font-size: 10px;
    background-color: unset;
    color: $c--white;
    border-width: 1px;
  }

  @include mobile {
    height: $header-icon-mobile;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
</style>
