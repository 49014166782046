<template>
  <div
    ref="oneSignalContainer"
    :data-ref="dataRef"
    class="header-actions__element_one-signal"
  >
    <!-- Note: this icon is going to replace OneSignal's default Bell SVG -->
    <a-one-signal-icon :width="size" :height="size" />
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import AOneSignalIcon from 'shared/AOneSignalIcon'

export default {
  name: 'SubscribeIcon',
  components: { AOneSignalIcon },
  props: {
    dataRef: propValidator([PROP_TYPES.STRING]),
    size: propValidator([PROP_TYPES.NUMBER])
  }
}
</script>

<style scoped lang="scss">
.header-actions__element_one-signal {
  position: relative;

  @include header-icon-size;
}
</style>
