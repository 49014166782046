<template>
  <div v-if="isIconVisible" :data-ref="REFS.ONE_SIGNAL_ICON">
    <a-icon
      :icon="currentIcon"
      :width="width"
      :height="height"
      no-hover
      original
    />
  </div>
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'
import { SHOW_ONE_SIGNAL_ICON_BUS_NAME } from 'enums/oneSignal'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { REFS } from 'enums/external-refs'

export default {
  name: 'AOneSignalIcon',
  components: { AIcon },
  props: {
    width: propValidator([PROP_TYPES.NUMBER]),
    height: propValidator([PROP_TYPES.NUMBER])
  },
  data() {
    return {
      ICON,
      REFS,
      currentIcon: ICON.MEGAPHONE_INACTIVE,
      isIconVisible: false
    }
  },
  methods: {
    showOneSignalIcon(isSubscribed) {
      this.currentIcon = isSubscribed
        ? ICON.MEGAPHONE_INACTIVE
        : ICON.MEGAPHONE_ACTIVE

      this.isIconVisible = true
    },
    garbageCollectIcon() {
      const parent = this.$el.parentElement

      if (!parent) return

      const elementToRemove = parent.querySelector(
        `[data-ref="${REFS.ONE_SIGNAL_ICON}"]`
      )

      if (!elementToRemove) return

      elementToRemove.parentElement.removeChild(elementToRemove)
    }
  },
  mounted() {
    this.$bus.$on(SHOW_ONE_SIGNAL_ICON_BUS_NAME, this.showOneSignalIcon)
  },
  beforeDestroy() {
    this.$bus.$off(SHOW_ONE_SIGNAL_ICON_BUS_NAME, this.showOneSignalIcon)
    this.garbageCollectIcon()
  }
}
</script>
